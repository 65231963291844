/* eslint-disable import/prefer-default-export */
export const waGratis = [
  {
    id: 1,
    text: '<b>Customer bisa lupa</b> kalo dia harus <b>siapkan uang</b> untuk pembayaran',
    check: false,
  },
  {
    id: 2,
    text: '<b>Customer kelupaan</b> menyiapkan uang ketika <b>bepergian dari rumah</b>',
    check: false,
  },
  {
    id: 3,
    text: 'Customer bisa <b>beralih</b> ke <b>penjual lain</b>',
    check: false,
  },
  {
    id: 4,
    text: '<b>Customer risau</b> karena tidak menerima <b>informasi paket</b>',
    check: false,
  },
  {
    id: 5,
    text: 'Kalo kamu update notif <b>manual</b> ke banyak custmer bisa <b>bikin pusing</b>',
    check: false,
  },
]

export const waPremium = [
  {
    id: 1,
    text: '<b>Kirim resi otomatis</b> ke customer saat produk sudah di pickup',
    check: true,
  },
  {
    id: 2,
    text: '<b>Notifikasi</b> ke customer <b>otomatis</b> saat paket sampai kota customer',
    check: true,
  },
  {
    id: 3,
    text: 'Notifikasi <b>pake no  WA kamu.</b> bikin kamu mudah berkomunikasi sama customer',
    check: true,
  },
  {
    id: 4,
    text: '<b>Template pesan</b> dapat kamu <b>sesuaikan kebutuhanmu</b>',
    check: true,
  },
  {
    id: 5,
    text: '<b>Berasa punya asisten</b> tanpa merekrut orang baru',
    check: true,
  },
]
