import { render, staticRenderFns } from "./Opsional.html?vue&type=template&id=6127aa16&scoped=true&"
import script from "./Opsional.js?vue&type=script&lang=js&"
export * from "./Opsional.js?vue&type=script&lang=js&"
import style0 from "./Opsional.scss?vue&type=style&index=0&id=6127aa16&lang=scss&scoped=true&"
import style1 from "./Opsional.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6127aa16",
  null
  
)

export default component.exports